<template>
  <div class="piece-box person-number">
    <div class="hr-number-piece">
      <a-row :gutter="16">
        <a-col :span="12" v-for="(item, i) in totalCount" :key="i">
          <a-statistic class="hr-number-item" :value="item.num" :value-style="valueStyle">
            <template v-slot:title>
              <div class="hr-number-top" :class="{'number-top-more': !!item.route}" @click="goRoute(item)">
                <div class="hr-number-title">{{ item.title }}</div>
                <a-icon class="hr-number-more" type="right" v-if="item.route" />
              </div>
            </template>
          </a-statistic>
        </a-col>
      </a-row>
    </div>
    <a-divider type="vertical" />
    <div class="hr-number-piece">
      <a-row :gutter="16">
        <a-col :span="12" v-for="(item, i) in volunteerCount" :key="i">
          <a-statistic class="hr-number-item" :value="item.num" :value-style="valueStyle">
            <template v-slot:title>
              <div class="hr-number-top" :class="{'number-top-more': !!item.route}" @click="goRoute(item)">
                <div class="hr-number-title">{{ item.title }}</div>
                <a-icon class="hr-number-more" type="right" v-if="item.route" />
              </div>
            </template>
          </a-statistic>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script >
    export default {
        name: "HRPersonNumber",
        props: {
            number: Array
        },
        data() {
            return {
                valueStyle: {
                    textAlign: "center"
                }
            }
        },
        computed: {
            totalCount() {
                const res = [
                    { title: "总人数", num: 0 },
                    { title: "僧众人数", num: 0, route: { name: "HRBook", params: {type: 'monk'} } },
                    { title: "职工人数", num: 0, route: { name: "HRBook", params: {type: 'staff'} } },
                    { title: "志工人数", num: 0, route: { name: "MemberList" } },
                ]
                const number = this.number;
                if(number) {
                    res[0].num = number[0] || 0;
                    res[1].num = number[1] || 0;
                    res[2].num = number[4] || 0;
                    res[3].num = number[5] || 0;
                }
                return res;
            },
            volunteerCount() {
                const res = [
                    { title: "志工日常组", num: 0, route: { name: "GroupList" } },
                    { title: "志工日常组人数", num: 0 },
                    { title: "志工专业组", num: 0, route: { name: "GroupList" } },
                    { title: "志工专业组人数", num: 0 },
                ]
                const number = this.number;
                if(number) {
                    res[0].num = number[2] || 0;
                    res[1].num = number[3] || 0;
                    res[2].num = number[6] || 0;
                    res[3].num = number[7] || 0;
                }
                return res;
            }
        },
        methods: {
            goRoute(item) {
                this.$router.push(item.route);
            }
        }
    }
</script>

<style lang="less">
.person-number {
  display: flex;
  align-items: center;
  padding: 16px 32px;
}
.hr-number-piece {
  flex: 1;
}
.hr-number-item {
  padding: 12px 0;
}
.hr-number-top {
  display: flex;
  align-items: center;
  justify-content: center;
  color: @text-color-secondary;
  &.number-top-more {
    cursor: pointer;
  }
}
.hr-number-more {
  margin-left: 8px;
}
.hr-number-num {
  margin-top: 6px;
  font-weight: bold;
  font-size: 32px;
  color: #333333;
  text-align: center;
  line-height: 1.2;
}
</style>
