<template>
    <div class="flex-box vertical">
        <div class="data-title">
            <a-form-model class="query-form" layout="inline">
                <a-form-model-item label="评价时间">
                    <a-range-picker
                            v-model="form.time"
                            :placeholder="['开始时间', '结束时间']"
                            format="YYYY-MM-DD"
                            value-format="YYYY-MM-DD"/>
                </a-form-model-item>
                <a-form-model-item label="状态">
                    <a-select
                            style="width: 180px"
                            placeholder="选择状态筛选"
                            @change="getList"
                            :options="statusList"
                            v-model="form.status">
                    </a-select>
                </a-form-model-item>
            </a-form-model>
            <a-space class="title-btn">
                <a-button @click="getList">查询</a-button>
                <!--        <a-button type="primary" @click="exportData">导出</a-button>-->
            </a-space>
        </div>
        <div class="data-area">
            <a-table
                    ref="dataTable"
                    :columns="columns"
                    :row-key="record => record.id"
                    :data-source="list"
                    :loading="loading"
                    :pagination="pagination"
                    :scroll="{y: h}"
                    @change="handleTableChange">
                <template v-slot:action="text, record">
                    <div class="row-btn">
                        <a class="txt-btn" @click.stop="showDetail(record)">查看详情</a>
                    </div>
                </template>
            </a-table>
        </div>
    </div>
</template>

<script>
    import {getUserWxId} from "../../../../common/js/storage";
    import {assMessageStatus, getAssMessageStatus} from "../../../../common/hr/apply";
    import {clone} from "../../../../common/js/tool";

    export default {
        name: "HRAssApprove",
        data() {
            return {
                loading: false,
                list: [],
                form: {
                    status: 0
                },
                h: 500,
                key: 1,
                columns: [
                    {title: '时间', dataIndex: 'create_time'},
                    {
                        title: '名称',
                        dataIndex: 'item',
                        customRender: text => {
                            return <span>{text && text.list ? text.list.title : '-'}</span>
                        }
                    },
                    {
                        title: '被评价人',
                        dataIndex: 'user_id',
                        customRender: text => {
                            return <open-data type="userName" openid={text} />
                        }
                    },
                    // {
                    //     title: '类型',
                    //     dataIndex: 'wx_user_id',
                    //     customRender: (text, record) => {
                    //         return <span>{text == record.user_id ? '自评' : '主管评价'}</span>
                    //     }
                    // },
                    {
                        title: '状态',
                        dataIndex: 'status',
                        customRender: text => {
                            return <span>{getAssMessageStatus(text)}</span>
                        }
                    },
                    {title: '操作', key: 'action', fixed: 'right', width: 120, scopedSlots: { customRender: 'action'}}
                ],
                pagination: {
                    total: 0,
                    current: 1,
                    pageSize: 10,
                    showSizeChanger: true
                },
                statusList: [{key: -1, title: '全部'}].concat(assMessageStatus),
                apply: null
            }
        },
        created() {
            this.getList();
        },
        mounted() {
            this.setHeight();
            window.addEventListener("resize", this.setHeight, false);
        },
        beforeDestroy() {
            window.removeEventListener("resize", this.setHeight, false);
        },
        methods: {
            exportData() {

            },
            hideApply() {
                this.apply = null;
            },
            showDetail(record) {
                const obj = clone(record);
                const type = 'assApprove';
                this.$store.commit("setDetail", {type: type, obj: obj})
                this.$router.push({name: "AssApproveDetail", params: {id: record.id}});
            },
            handleTableChange(pagination) {
                let pager = { ...this.pagination };
                pager.current = pagination.current;
                pager.pageSize = pagination.pageSize;
                this.pagination = pager;
                this.getList();
            },
            getList() {
                let uid = getUserWxId();
                let url = `/admin/evaluate-message?sort=-id&filter[wx_user_id]=${uid}`;
                let {current, pageSize} = this.pagination;
                url += `&page=${current}&pageSize=${pageSize}`;
                let form = this.form;
                if(form.status != -1) {
                    url += `&filter[status]=${form.status}`
                }
                if(form.time && form.time.length == 2) {
                    let start = form.time[0] + ' 00:00:00'
                    let end = form.time[1] + ' 23:59:59'
                    url += `&filter[create_time][gte]=${start}&filter[create_time][lt]=${end}`;
                }
                this.$axios(url).then(res => {
                    let list = res.data;
                    this.list = list;
                    if(res.page) {
                        this.$set(this.pagination, 'total', res.page.totalCount);
                    }
                });
            },
            setHeight() {
                let dataTale = this.$refs.dataTable;
                if(dataTale) {
                    let area = this.$el.querySelector(".data-area");
                    this.h = area.clientHeight - 54 - 64;
                }
            },
        }
    }
</script>

<style scoped lang="less">
</style>
