<template>
  <div class="piece-tab">
    <div class="piece-tab-item" :class="{'piece-tab-active': tab == t.key}" v-for="(t, i) in tabs" :key="i">
      <div class="piece-tab-divider" v-if="i > 0">/</div>
      <div class="piece-tab-title" @click="selectTab(t)">{{ t.title }}</div>
    </div>
  </div>
</template>

<script>

    export default {
        name: "piece-tab",
        model: {
            event: "change",
        },
        props: {
            value: [Number, String],
            tabs: Array
        },
        computed: {
            tab: {
                get() {
                    return this.value;
                },
                set(val) {
                    this.$emit("change", val)
                }
            }
        },
        methods: {
            selectTab(t) {
                this.tab = t.key;
            }
        }
    }
</script>

<style lang="less">
  .piece-tab {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: @text-color-secondary;
  }
  .piece-tab-item {
    display: flex;
    align-items: center;
    &.piece-tab-active {
      color: @primary-color;
    }
  }
  .piece-tab-divider {
    margin: 0 8px;
  }
  .piece-tab-title {
    cursor: pointer;
  }
</style>
