<template>
  <div class="piece-box personnel-matter">
    <a-tabs :active-key="key" @tabClick="handleClick">
      <a-tab-pane :key="1" tab="我的评价">
        <div class="personnel-matter-content">
          <hr-ass-approve style="height: 100%;"></hr-ass-approve>
        </div>
      </a-tab-pane>
      <a-tab-pane :key="2" tab="待我审批">
        <div class="personnel-matter-content">
          <hr-approve style="height: 100%;"></hr-approve>
        </div>
      </a-tab-pane>
      <a-tab-pane :key="3" tab="我的申请">
        <div class="personnel-matter-content">
          <hr-apply style="height: 100%;"></hr-apply>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
    import HrApprove from "./HRApprove";
    import HrAssApprove from "./HRAssApprove";
    import HrApply from "./HRApply";
    export default {
        name: "PersonnelMatter",
        components: {
            HrApprove,
            HrAssApprove,
            HrApply,
        },
        data() {
            return {
                key: 1,
            }
        },
        methods: {
            handleClick(e) {
                this.key = e;
            },
        }
    }
</script>

<style lang="less">
.personnel-matter {
  padding: 0 16px;
}
.personnel-matter-content {
  height: 557px;
}
</style>
