<template>
  <div class="msg-list">
    <template v-if="arr && arr.length > 0">
      <div class="msg-item" @click="showDetail(msg)" v-for="msg in arr" :key="msg.id">
        <a-icon type="file-text" />
        <div class="msg-title">{{msg.title}}</div>
        <div class="msg-date">{{msg.date}}</div>
        <a-icon type="right" />
      </div>
    </template>
    <a-empty :image="emptyImage" v-else-if="arr"/>
    <a-modal
      :title="apply ? apply.title : ''"
      centered
      :width="600"
      v-model="ds">
      <detail-view :list="apply && apply.showList ? apply.showList : []"></detail-view>
    </a-modal>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue';
import {dealHRApply, getApplyMsgTitle} from "../common/hr/apply";
import {getDate} from "../common/js/tool";
import detailView from "../components/detail-view"
export default {
  name: "MsgList",
  props: {
    list: Array
  },
  components: {detailView},
  data() {
    return {
      apply: null,
      ds: false,
      emptyImage: Empty.PRESENTED_IMAGE_SIMPLE
    }
  },
  computed: {
    arr() {
      let list = this.list;
      if(list){
        return list.map(item => {
          return {
            ...item,
            title: getApplyMsgTitle(item.applyTemp.type),
            date: getDate(item.update_time).pattern("yyyy-MM-dd")
          }
        })
      } else {
        return null
      }
    }
  },
  methods: {
      getShowList(item) {
          let keys = [];
          if(item.applyTemp) {
              let type = item.applyTemp.type;
              switch (type * 1) {
                case 1:
                    keys = ['fm'];
                    break;
                case 3:
                    keys = ['name'];
                    break;
                case 5:
                    keys = ['staff', 'old_dept', 'old_post', 'new_dept', 'new_post'];
                    break;
              }
          }
          return [{label: '公示日期', value: item.date}].concat(keys.map(key => item.form.find(f => f.key == key)));
      },
      hideApply() {
          this.apply = null;
          this.ds = false;
      },
      showDetail(record) {
          dealHRApply(record);
          if(!record.showList) {
              record.showList = this.getShowList(record);
          }
          this.apply = record;
          this.ds = true;
      },
  }
}
</script>

<style scoped lang="less">
  .msg-item {
    display: flex;
    align-items: center;
    height: 36px;
    cursor: pointer;
    &:hover {
      background-color: @background-color-light;
    }
  }
  .msg-title {
    flex: 1;
    min-width: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
</style>
