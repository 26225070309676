<template>
  <div class="flex-box vertical">
    <div class="data-title">
      <a-form-model class="query-form" layout="inline">
        <a-form-model-item label="申请时间">
          <a-range-picker
            v-model="form.time"
            :placeholder="['开始时间', '结束时间']"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"/>
        </a-form-model-item>
        <a-form-model-item label="状态">
          <a-select
            style="width: 180px"
            placeholder="按审批状态筛选"
            @change="getList"
            :options="status"
            v-model="form.status">
          </a-select>
        </a-form-model-item>
      </a-form-model>
      <div class="title-btn">
        <a-button @click="getList">查询</a-button>
      </div>
    </div>
    <div class="data-area">
      <a-table
        ref="dataTable"
        :columns="columns"
        row-key="id"
        :data-source="list"
        :loading="loading"
        :pagination="pagination"
        :scroll="{y: h}"
        @change="handleTableChange">
        <template v-slot:action="text, record">
          <div class="row-btn">
            <a class="txt-btn" @click.stop="showDetail(record)">查看</a>
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
  import {hrApplyStatus, getHRApplyStatusText} from "../../../../common/hr/apply";
  import {getUserWxId, getTemple, getStorageInfo} from "../../../../common/js/storage";
  import {getHRTempTypeName} from "../../../../common/constant/template";
  import {getHRRole} from "../../../../common/constant/org";

  export default {
    name: "HRApply",
    data() {
      return {
        list: [],
        columns: [
          {title: '申请时间', dataIndex: 'create_time'},
          {title: '申请类型', dataIndex: 'typeText'},
          {title: '状态', dataIndex: 'statusText'},
          {title: '操作', key: 'action', fixed: 'right', scopedSlots: { customRender: 'action'}}
        ],
        form: {},
        loading: false,
        status: [{key: 0, title: '全部'}].concat(hrApplyStatus),
        pagination: {
          total: 0,
          current: 1,
          pageSize: 10,
          showSizeChanger: true
        },
        h: 500,
        user: null,
        role: getHRRole(),
      }
    },
    created() {
      let user = getStorageInfo();
      this.user = user ? user.evaluate_user : null;
      this.getList();
    },
    mounted() {
      this.setHeight();
      window.addEventListener("resize", this.setHeight, false);
    },
    beforeDestroy() {
      window.removeEventListener("resize", this.setHeight, false);
    },
    methods: {
      goHRForm(type) {
        this.$router.push({name: 'HRBookForm', params: {type}})
      },
      showDetail(record) {
        this.$store.commit("setApply", record);
      },
      handleTableChange(pagination) {
        let pager = { ...this.pagination };
        pager.current = pagination.current;
        pager.pageSize = pagination.pageSize;
        this.pagination = pager;
        this.getList();
      },
      getList() {
        let url = `/admin/apply?sort=-create_time&filter[user_id]=${getUserWxId()}&temple_id=${getTemple()}&is_oa=2`;
        let {current, pageSize} = this.pagination;
        url += `&page=${current}&pageSize=${pageSize}`;
        let form = this.form;
        if(form.status > 0) {
          url += `&filter[status]=${form.status}`
        }
        if(form.time && form.time.length == 2) {
          let start = form.time[0] + ' 00:00:00'
          let end = form.time[1] + ' 23:59:59'
          url += `&filter[create_time][gte]=${start}&filter[create_time][lt]=${end}`;
        }
        this.$axios({url, noTempleFilter: true}).then(res => {
          let list = res.data;
          list.forEach(item => {
            item.statusText = getHRApplyStatusText(item.status);
            let type = item.applyTemp.type;
            item.typeText = getHRTempTypeName(type);
          })
          this.list = list;
          if(res.page) {
            this.$set(this.pagination, 'total', res.page.totalCount);
          }
        });
      },
      setHeight() {
        let dataTale = this.$refs.dataTable;
        if(dataTale) {
          let area = this.$el.querySelector(".data-area");
          this.h = area.clientHeight - 54 - 64;
        }
      },
    }
  }
</script>

<style scoped lang="less">
  .hr-apply-title {
    font-size: 16px;
    font-weight: 600;
  }
  .hr-apply-top {
    padding-bottom: 16px;
    border-bottom: var(--border);
  }
  .hr-apply-type {
    margin-top: 16px;
  }
</style>
