<template>
  <div class="piece-box inner-content personnel-changes">
    <div class="piece-box-title flex-box align-center">
      <div class="flex-grow">人员变动</div>
      <piece-tab v-model="type" :tabs="personTypes"></piece-tab>
    </div>
    <div class="personnel-changes-chart"></div>
    <piece-tab class="personnel-changes-time" v-model="timeType" :tabs="timeTabs"></piece-tab>
  </div>
</template>

<script>
    import * as echarts from "echarts";
    import pieceTab from "../../../../components/piece-tab";
    const options = {
        grid: {
            top: 40,
            left: 20,
            right: 20,
            bottom: 30,
            containLabel: true
        },
        xAxis: {
            type: 'category',
            data: ['进单', '离单', '转正', '调岗', '外派'],
            axisTick: {
                show: false,
            },
            axisLabel: {
                fontSize: 12,
                color: '#1f63ca'
            },
            axisLine: {
                lineStyle: {
                    color: 'rgba(31,99,202,0.3)'
                }
            }
        },
        yAxis: {
            type: 'value',
            axisTick: {
                show: false,
            },
            axisLabel: {
                fontSize: 12,
                color: '#1f63ca'
            },
            axisLine: {
                show: true,
                lineStyle: {
                    color: 'rgba(31,99,202,0.3)'
                }
            },
            splitLine: {
                show: false
            }
        },
        series: [
            {
                data: [120, 200, 150, 80, 70],
                type: 'bar',
                itemStyle: {
                    color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [{
                            offset: 0, color: '#4887e6' // 0% 处的颜色
                        }, {
                            offset: 1, color: '#1f63ca' // 100% 处的颜色
                        }],
                        global: false // 缺省为 false
                    },
                },
                barWidth: 16
            }
        ]
    }
    export default{
        name: "PersonnelChanges",
        components: {
            pieceTab
        },
        props: {
            number: Array
        },
        data() {
            return {
                type: 1,
                timeType: "month",
                timeTabs: [
                    { key: "month", title: "当月" },
                    { key: "year", title: "当年" },
                ]
            }
        },
        computed: {
            personTypes() {
                const res = [
                    {
                        key: 1,
                        title: "僧众",
                        count: {
                            month: [
                                { value: 0, name: "进单" },
                                { value: 0, name: "转正" },
                                { value: 0, name: "调岗" },
                                { value: 0, name: "离单" },
                            ],
                            year: [
                                { value: 0, name: "进单" },
                                { value: 0, name: "转正" },
                                { value: 0, name: "调岗" },
                                { value: 0, name: "离单" },
                            ]
                        }
                    },
                    {
                        key: 2,
                        title: "职工",
                        count: {
                            month: [
                                { value: 0, name: "入职" },
                                { value: 0, name: "转正" },
                                { value: 0, name: "调岗" },
                                { value: 0, name: "离职" },
                            ],
                            year: [
                                { value: 0, name: "入职" },
                                { value: 0, name: "转正" },
                                { value: 0, name: "调岗" },
                                { value: 0, name: "离职" },
                            ]
                        }
                    },
                    {
                        key: 3,
                        title: "志工",
                        count: {
                            month: [
                                { value: 0, name: "待面试" },
                                { value: 0, name: "实习期" },
                                { value: 0, name: "正式" },
                                { value: 0, name: "休眠" },
                                { value: 0, name: "休眠激活" },
                            ],
                            year: [
                                { value: 0, name: "待面试" },
                                { value: 0, name: "实习期" },
                                { value: 0, name: "正式" },
                                { value: 0, name: "休眠" },
                                { value: 0, name: "休眠激活" },
                            ]
                        }
                    },
                ];
                const number = this.number;
                if(number) {
                    res.forEach((item, i) => {
                        const count = number[i];
                        if(count) {
                            for(let key in item.count) {
                                const d = item.count[key];
                                if(count[key]) {
                                    d.forEach((c, j) => c.value = count[key][j] || 0);
                                }
                            }
                        }
                    });
                }
                return res;
            }
        },
        watch: {
            type() {
                this.initChart();
            },
            timeType() {
                this.initChart();
            },
            personTypes() {
                this.initChart();
            }
        },
        mounted() {
            this.initChart();
        },
        methods: {
            initChart() {
                if(!this.chart) {
                    this.chart = echarts.init(this.$el.querySelector(".personnel-changes-chart"));
                }
                const type = this.personTypes.find(t => t.key == this.type);
                if(type) {
                    const data = [...type.count[this.timeType]];
                    options.xAxis.data = data.map(item => item.name);
                    options.series[0].data = data.map(item => item.value);
                }
                this.chart.setOption(options);
            }
        }
    }
</script>

<style lang="less">
.personnel-changes {
  position: relative;
}
.personnel-changes-chart {
  height: 240px;
}
.personnel-changes-time {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 16px;
  justify-content: center;
}
</style>
