<template>
    <div class="flex-box vertical">
        <div class="data-title">
            <a-form-model class="query-form" layout="inline">
                <a-form-model-item label="申请时间">
                    <a-range-picker
                            v-model="form.time"
                            :placeholder="['开始时间', '结束时间']"
                            format="YYYY-MM-DD"
                            value-format="YYYY-MM-DD"/>
                </a-form-model-item>
                <a-form-model-item label="状态">
                    <a-select
                            style="width: 180px"
                            placeholder="选择状态筛选"
                            @change="getList"
                            :options="statusList"
                            v-model="form.status">
                    </a-select>
                </a-form-model-item>
            </a-form-model>
            <a-space class="title-btn">
                <a-button @click="getList">查询</a-button>
                <!--        <a-button type="primary" @click="exportData">导出</a-button>-->
            </a-space>
        </div>
        <div class="data-area">
            <a-table
                    ref="dataTable"
                    :columns="columns"
                    :row-key="record => record.id"
                    :data-source="list"
                    :loading="loading"
                    :pagination="pagination"
                    :scroll="{y: h}"
                    @change="handleTableChange">
                <template v-slot:name="text">
                    <open-data type="userName" :openid="text" />
                </template>
                <template v-slot:action="text, record">
                    <div class="row-btn">
                        <a class="txt-btn" @click.stop="showDetail(record)">查看详情</a>
                    </div>
                </template>
            </a-table>
        </div>
    </div>
</template>

<script>
    import {getUserWxId, getTemple} from "../../../../common/js/storage";
    import {hrApplyStatus, getHRApplyStatusText} from "../../../../common/hr/apply";
    import {getHRTempTypeName} from "../../../../common/constant/template";

    export default {
        name: "HRApprove",
        data() {
            return {
                loading: false,
                list: [],
                form: {status: 1},
                h: 500,
                key: 1,
                columns: [
                    {title: '申请时间', dataIndex: 'create_time'},
                    {title: '申请人', dataIndex: 'user_id', scopedSlots: { customRender: 'name'}},
                    {title: '状态', dataIndex: 'statusText'},
                    {title: '操作', key: 'action', fixed: 'right', width: 120, scopedSlots: { customRender: 'action'}}
                ],
                pagination: {
                    total: 0,
                    current: 1,
                    pageSize: 10,
                    showSizeChanger: true
                },
                statusList: [{key: 0, title: '全部'}].concat(hrApplyStatus),
            }
        },
        created() {
            this.getList();
        },
        mounted() {
            this.setHeight();
            window.addEventListener("resize", this.setHeight, false);
        },
        beforeDestroy() {
            window.removeEventListener("resize", this.setHeight, false);
        },
        methods: {
            exportData() {

            },
            handleOperate() {
                this.hideApply();
                this.getList();
            },
            showDetail(record) {
                // dealHRApply(record);
                this.$store.commit("setApply", record);
            },
            handleTableChange(pagination) {
                let pager = { ...this.pagination };
                pager.current = pagination.current;
                pager.pageSize = pagination.pageSize;
                this.pagination = pager;
                this.getList();
            },
            getList() {
                let uid = getUserWxId();
                let url = `/admin/apply-message?sort=-id&expand=apply&filter[book_id]=${uid}&is_oa=2&temple_id=${getTemple()}`;
                let {current, pageSize} = this.pagination;
                url += `&page=${current}&pageSize=${pageSize}`;
                let form = this.form;
                if(form.status > 0) {
                    url += `&status=${form.status}`
                }
                if(form.time && form.time.length == 2) {
                    let start = form.time[0] + ' 00:00:00'
                    let end = form.time[1] + ' 23:59:59'
                    url += `&apply_start_time=${start}&apply_end_time=${end}`;
                }
                this.$axios({url, noTempleFilter: true}).then(res => {
                    let list = res.data.map(item => {
                        let apply = item.apply;
                        apply.message = {id: item.id, node_id: item.node_id};
                        apply.message_id = item.id;
                        if(item.extraApply) {
                            apply.extraApply = item.extraApply;
                        }
                        apply.statusText = getHRApplyStatusText(apply.status);
                        let type = apply.applyTemp.type;
                        apply.typeText = getHRTempTypeName(type);
                        return apply;
                    });
                    this.list = list;
                    if(res.page) {
                        this.$set(this.pagination, 'total', res.page.totalCount);
                    }
                });
            },
            setHeight() {
                let dataTale = this.$refs.dataTable;
                if(dataTale) {
                    let area = this.$el.querySelector(".data-area");
                    this.h = area.clientHeight - 54 - 64;
                }
            },
        }
    }
</script>

<style scoped lang="less">
</style>
