<template>
  <div class="piece-box inner-content personnel-message">
    <div class="piece-box-title">动态通知</div>
    <msg-list :list="list" style="margin-top: 8px"></msg-list>
    <a-pagination v-bind="pagination" @change="handlePageChange" size="small" style="margin-top: 8px;text-align: center"/>
  </div>
</template>

<script>
    import msgList from "../../../../layouts/MsgList";
    import {getTemple} from "../../../../common/js/storage";
    export default {
        name: "PersonnelMessage",
        components: {
            msgList
        },
        data() {
            return {
                list: [],
                pagination: {
                    total: 0,
                    current: 1,
                    pageSize: 10
                },

            }
        },
        created() {
            this.getList();
        },
        methods: {
            getList() {
                let url = `/admin/apply?sort=-id&is_oa=2&temp_type=1,3,5&temple_id=${getTemple()}&filter[status]=4`;
                let {current, pageSize} = this.pagination;
                url += `&page=${current}&pageSize=${pageSize}`;
                this.$axios({url, noTempleFilter: true}).then(res => {
                    this.list = res.data;
                    this.$set(this.pagination, 'total', res.page.totalCount)
                })
            },
            handlePageChange(page) {
                let pager = { ...this.pagination };
                pager.current = page.current;
                this.pagination = pager;
                this.getList();
            },
        }
    }
</script>

<style lang="less">

</style>
