<template>
  <div class="flex-grow scroll-area">
    <div class="hr-workbench">
      <a-row :gutter="[16, 16]" type="flex" v-if="role == 1">
        <a-col :span="14">
          <person-number :number="countData.count" class="flex-grow"></person-number>
        </a-col>
        <a-col :span="10">
          <div class="piece-box person-info">
            <staff-brief
              reversed
              :item-span="24"
              :info="user.evaluate_user"
              v-if="user && user.mobile && user.evaluate_user"></staff-brief>
            <div class="home-info-tip" v-else-if="user">
              <span>您的资料尚未完善</span>
              <router-link replace :to="{name: 'HRBookForm', params: {type: 'staff'}, query: {b: user.wx_user_id}}" class="txt-btn">去完善</router-link>
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <attendance-statistics :number="countData.kqtz"></attendance-statistics>
        </a-col>
        <a-col :span="8">
          <personnel-changes :number="countData.rybd" />
        </a-col>
        <a-col :span="8">
          <volunteer-statistics :number="countData.zgtz" />
        </a-col>
        <a-col :span="16">
          <personnel-matter />
        </a-col>
        <a-col :span="8">
          <quick-entry />
          <personnel-message style="margin-top: 16px" />
        </a-col>
      </a-row>
      <a-row :gutter="[16, 16]" type="flex"  v-else>
        <a-col :span="14">
          <div class="piece-box person-info">
            <staff-brief
              reversed
              :item-span="12"
              :info="user.evaluate_user"
              v-if="user && user.mobile && user.evaluate_user"></staff-brief>
            <div class="home-info-tip" v-else-if="user">
              <span>您的资料尚未完善</span>
              <router-link replace :to="{name: 'HRBookForm', params: {type: 'staff'}, query: {b: user.wx_user_id}}" class="txt-btn">去完善</router-link>
            </div>
          </div>
        </a-col>
        <a-col :span="10">
          <quick-entry class="person-info" />
        </a-col>
        <a-col :span="16">
          <personnel-matter />
        </a-col>
        <a-col :span="8">
          <personnel-message />
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
    import PersonNumber from "./layouts/PersonNumber";
    import staffBrief from "../../../components/staff-brief";
    import AttendanceStatistics from "./layouts/AttendanceStatistics";
    import PersonnelChanges from "./layouts/PersonnelChanges";
    import VolunteerStatistics from "./layouts/VolunteerStatistics";
    import PersonnelMatter from "./layouts/PersonnelMatter";
    import PersonnelMessage from "./layouts/PersonnelMessage";
    import QuickEntry from "./layouts/QuickEntry";
    import {clone} from "@/common/js/tool";
    import {getUser} from "@/common/js/storage";
    import {dealStaff} from "@/common/hr/book";
    import {getHRRole} from "@/common/constant/org";
    export default {
        name: "HRWorkbench",
        components: {
            PersonNumber,
            staffBrief,
            AttendanceStatistics,
            PersonnelChanges,
            VolunteerStatistics,
            PersonnelMatter,
            QuickEntry,
            PersonnelMessage,
        },
        data() {
            return {
                user: null,
                role: getHRRole(),
                countData: {}
            }
        },
        created() {
            this.getStaff();
            this.getHrData();
        },
        methods: {
            getStaff() {
                const user = clone(getUser());
                if(user.evaluate_user) {
                    user.evaluate_user = dealStaff(user.evaluate_user);
                }
                this.user = user;
            },
            getHrData() {
                this.$axios({
                    url: "/admin/evaluate-item/general",
                    noTempleFilter: true
                }).then(res => {
                     this.countData = res;
                });
            }
        }
    }
</script>

<style lang="less">
.hr-workbench {
  margin: 0 auto;
  width: 1400px;
}
.person-info {
  height: 202px;
}
</style>
