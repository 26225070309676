<template>
  <div class="piece-box inner-content attendance-statistics">
    <div class="piece-box-title flex-box align-center">
      <div class="flex-grow">考勤统计</div>
      <piece-tab v-model="type" :tabs="personTypes"></piece-tab>
    </div>
    <div class="attendance-statistics-chart"></div>
  </div>
</template>

<script>
    import * as echarts from "echarts";
    import pieceTab from "../../../../components/piece-tab";
    const options = {
        title: {
            top: "middle",
            left: "34%",
            textVerticalAlign: "top",
            textAlign: "center",
            text: 223,
            subtext: "应到人数"
        },
        legend: {
            orient: "vertical",
            top: "middle",
            right: "5%",
        },
        series: [
            {
                name: '考勤统计',
                type: 'pie',
                radius: ['50%', '80%'],
                center: ['35%', '50%'],
                avoidLabelOverlap: false,
                itemStyle: {
                    borderRadius: 10,
                    borderColor: '#fff',
                    borderWidth: 2
                },
                label: {
                    position: 'inside',
                    formatter: "{d}%",
                },
                labelLine: {
                    show: false
                },
                data: []
            }
        ]
    };
    export default{
        name: "AttendanceStatistics",
        components: {
            pieceTab
        },
        props: {
            number: Array
        },
        data() {
            return {
                type: 1,
            }
        },
        computed: {
            personTypes() {
                const res = [
                    {
                        key: 1,
                        title: "僧众",
                        total: 0,
                        totalTitle: "应到人数",
                        count: [
                            { value: 0, name: "在寺" },
                            { value: 0, name: "请假" },
                            { value: 0, name: "外派" },
                        ]
                    },
                    {
                        key: 2,
                        title: "职工",
                        total: 0,
                        totalTitle: "应到人数",
                        count: [
                            { value: 0, name: "在寺" },
                            { value: 0, name: "请假" },
                            { value: 0, name: "外派" },
                        ]
                    },
                    {
                        key: 3,
                        title: "志工",
                        total: 0,
                        totalTitle: "应打卡人数",
                        count: [
                            { value: 0, name: "今日应打卡" },
                            { value: 0, name: "今日未打卡" },
                            { value: 0, name: "今日已打卡" },
                        ]
                    },
                ];
                const number = this.number;
                if(number) {
                    res.forEach((item, i) => {
                        const count = number[i];
                        if(count) {
                            item.count.forEach((c, j) => c.value = count[j + 1] || 0);
                            if(i == 2) {
                                item.total = item.count[0].value;
                            } else {
                                item.total = count[0] || 0;
                            }
                        }
                    });
                }
                return res;
            }
        },
        watch: {
            type() {
                this.initChart();
            },
            personTypes() {
                this.initChart();
            }
        },
        mounted() {
            this.initChart();
        },
        methods: {
            initChart() {
                if(!this.chart) {
                    this.chart = echarts.init(this.$el.querySelector(".attendance-statistics-chart"));
                }
                const type = this.personTypes.find(t => t.key == this.type);
                if(type) {
                    options.title.text = type.total;
                    options.title.subtext = type.totalTitle;
                    options.series[0].data = [...type.count];
                }
                this.chart.setOption(options);
            }
        }
    }
</script>

<style lang="less">
.attendance-statistics-chart {
  height: 240px;
}
</style>
