<template>
  <div class="piece-box quick-entry">
    <a-row :gutter="[16,16]">
      <a-col :span="12" v-for="(item, i) in list" :key="i">
        <div class="quick-entry-item" @click="goHRForm(item.type)">{{ item.title }}</div>
      </a-col>
    </a-row>
  </div>
</template>

<script>

    import {getStorageInfo} from "../../../../common/js/storage";
    import {getHRRole} from "../../../../common/constant/org";

    export default {
        name: "QuickEntry",
        data() {
            return {
                user: null,
                role: getHRRole(),
            }
        },
        created() {
            let user = getStorageInfo();
            this.user = user ? user.evaluate_user : null;
        },
        computed: {
            list() {
                let res = [];
                const type = this.user ? this.user.type : 0;
                if(type > 0) {
                    if(type == 1) {
                        res = [
                            {type: "monk", title: "进单"},
                            {type: "um", title: "离单"},
                        ]
                    } else if(type == 2) {
                        res = [
                            {type: "board", title: "入职"},
                            {type: "ub", title: "离职"},
                        ]
                    }
                    if(this.role < 3) {
                        res.push({type: "trans", title: "调岗"})
                    }
                } else {
                    res = [
                        {type: "monk", title: "进单"},
                        {type: "board", title: "入职"},
                    ]
                }
                return res;
            }
        },
        methods: {
            goHRForm(type) {
                this.$router.push({name: 'HRBookForm', params: {type}})
            },
        }
    }
</script>

<style lang="less">
.quick-entry {
  padding: 16px 32px;
}
.quick-entry-item {
  height: 48px;
  border-radius: 4px;
  background-color: @background-color-base;
  text-align: center;
  font-size: 16px;
  color: @text-color;
  line-height: 48px;
  cursor: pointer;
}
</style>
